export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara ändringar"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparar..."])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Idag kl. ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Igår kl. ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inte tillåtelse att prata i denna kanal."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej ansluten"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bifoga ett meddelande"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ditt meddelande"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa Profil"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera Roller"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicka"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannlys"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera Användare:Tagg"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera Som Läst"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverinställningar"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avisering"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Emojis"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startalternativ"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programaktivitet"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera bottar"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gränssnitt"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera Konto"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringslogg"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera Din Profil"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra Lösenord"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länka om Google Drive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länka Google Drive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer Profil"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna information kan visas av alla."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra dina aviseringsinställningar"])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviseringsljud"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gör ett coolt ljud för att få din uppmärksamhet"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push-meddelande"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få push-meddelanden även när appen är stängd."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp dina egna fina emojis, helt gratis! Emojis måste vara mindre än 3MB i storlek."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stöttade typer är: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till emojis"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrivbordsappens startalternativ"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att modifiera dessa inställningar måste du installera Nertivia's skrivbordsapp"])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna Nertivia vid datorns uppstart"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela program eller spel som du gör genom att lägga till appar nedan. Detta kommer överskrida din egna status när ett program öppnas, och återgå när programmet stängs."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att använda programaktivitetsfunktionen måste du ladda ner Nertivia's skrivbortsapp"])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa botanvändare vilket kommer tillåta dig att använda våran API för att skapa dina egna fina bottar."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till botkommandon för att enkelt meddela användaren vilka kommandon som existerar för din bot."])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa Bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg Till Kommando"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera Kommandon"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa Inbjudningslänk"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera Inbjudningslänk"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Nyckel"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa Nyckel"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera Nyckel"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ Nyckel"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera Bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du är på väg att radera ditt konto"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vad som kommer raderas"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vad som inte kommer raderas"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina meddelanden som skickas till offentliga servrar"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krav"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna/Radera alla servrar"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera alla bottar"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera Mitt Konto"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidra genom att skicka in ett PR på GitHub repo't."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översättare"])}
    }
  },
  "server-settings": {
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Kanaler"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Roller"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Inbjudningar"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serversynlighet"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Aviseringar"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannlysta Användare"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera Användare"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera Server"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " skriver..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " och ", _interpolate(_list(1)), " skriver..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " och ", _interpolate(_list(2)), " skriver..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " personer skriver..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänner"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyliga"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparade Anteckningar"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg Till Vän"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviseringar"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att lägga till egen status"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en vän genom att skriva deras användarnamn och tagg."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägger till..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg Till Server"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med i en server genom att ange inbjudningskoden."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa din egna server. Börja med att namnge den."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])}
    }
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
  }
}